import { useRootLoaderData } from '@storefront/ui-components/hooks/useRootLoaderData';

export interface UserLocation {
  city: string;
  postalCode: string;
  isSupportedPostalCode: boolean;
  suggested?: boolean;
  isPurchasable?: boolean;
  warehouseId?: string;
  partnerId?: string;
}

/**
 * Custom hook to get the user's location data.
 * It checks if the location is purchasable based on postal code support.
 *
 * @returns {UserLocation} User location data including purchasability status.
 */
const useUserLocation = (): UserLocation => {
  const userData = useRootLoaderData();
  const userLocation = userData?.location as UserLocation;

  // If location data is not available, return a default object
  if (!userLocation) {
    return { city: '', postalCode: '', isSupportedPostalCode: false, isPurchasable: false };
  }

  // Determine if the location is purchasable (consider undefined postal code as purchasable)
  const isPurchasable = userLocation.postalCode === undefined ? true : userLocation.isSupportedPostalCode;
  const warehouseId = userLocation.warehouseId;
  const partnerId = userLocation.partnerId;

  return {
    ...userLocation,
    isPurchasable,
    warehouseId,
    partnerId,
  };
};

export default useUserLocation;
